import axios from '../lib/axios-auth.js';
import config from '../lib/config.js';
import { onFormSubmit } from '../lib/form-submit.js';
import { trackEvent } from '../lib/event-tracking.js';

import { formatPhoneNumber } from './phone-formatting.js';

const resetForm = (form) => {
    form.reset();

    const radios = form.querySelectorAll('input[type="radio"]');

    radios.forEach(input => {
        input.checked = false;
    });
};

const onSubmit = (event) => {
    event.preventDefault();

    const form = event.target;

    onFormSubmit(form, `${config.API_BASE_ROUTE}pricing/signup`);

    form.addEventListener('success', (event) => {
        const successMsg = `<div class="success-box u-margin--t-m"><p>${event.data.success}</p></div>`;
        form.querySelector('.js-form-response').innerHTML = successMsg;

        resetForm(form);

        trackEvent('Signup Form', 'Form Submit', 'Signup Successful Submit');

        setTimeout(() => {
            window.location.replace(`${window.location.protocol}//${window.location.host}`);
        }, 7000);
    });
};

const checkCoupon = (event) => {
    console.log('hi');
    event.preventDefault();

    const couponError = document.querySelector('[data-element="coupon-code-error"]');
    const coupon_code = document.querySelector('[name="coupon"]').value;

    const data = {
        coupon_code
    };

    axios.post(`${config.API_BASE_ROUTE}small-business/check-coupon`, data)
        .then(({ data }) => {
            // Remove an error if there is one
            couponError.innerHTML = `<div class="success-box u-margin--t-m">Coupon code has been applied!</div>`;
        }).catch(({ response }) => {
            const data = response.data;

            couponError.innerHTML = `<div class="error-box u-margin--t-m">${data.error}</div>`;
        });
}

const formSignup = {
    init () {
        const form = document.querySelector('[name="pricing_signup"]');

        if (!form) {
            return;
        }

        // Set up phone number formatting
        formatPhoneNumber(form);

        document.querySelector('[data-element="coupon-code-apply"]').addEventListener('click', checkCoupon);

        // Add form response div
        const response = document.createElement('div');
        response.classList.add('js-form-response');
        form.appendChild(response);

        form.addEventListener('submit', onSubmit);
    }
};

export default formSignup;
