/**
 * Render a recaptcha inside an HTML element
 * @param {HTMLElement} form - the form containing the reCaptcha
 * @param {String} selector - the selector for the element where the recpatcha will be rendered
 */
const renderRecaptcha = (form, selector = '.g-recaptcha') => {
    if (typeof grecaptcha !== 'undefined') {
        const formRecaptcha = form.querySelector(selector);

        // If recaptcha has not already been initialized
        if (!formRecaptcha.hasAttribute('data-widget-id')) {
            const widgetId = grecaptcha.render(formRecaptcha.id,
                {
                    'sitekey': window.__RECAPTCHA_PUBLIC_KEY__
                }
            );

            formRecaptcha.setAttribute('data-widget-id', widgetId);
        }
    }
};

/**
 * Reset all recaptcha instances on the page.
 */
const resetRecaptchas = () => {
    const allRecaptchas = document.querySelectorAll('.g-recaptcha');

    // Reset captcha (if exists) (https://developers.google.com/recaptcha/docs/display)
    if (typeof grecaptcha !== 'undefined' && allRecaptchas) {
        allRecaptchas.forEach((node, index) => {
            grecaptcha.reset(index);
        });
    }
};

export { renderRecaptcha, resetRecaptchas };
