import Cookies from 'js-cookie';

import axios from '../lib/axios-auth.js';
import config from '../lib/config.js';
import { onFormSubmit } from '../lib/form-submit.js';
import { trackEvent } from '../lib/event-tracking.js';

import { formatPhoneNumber } from './phone-formatting.js';

function onSubmit (event) {
    event.preventDefault();

    const form = event.target;

    onFormSubmit(form, `${config.API_BASE_ROUTE}small-business/create`);

    form.addEventListener('success', (event) => {
        const successMsg = `<div class="success-box u-margin--t-m"><p>${event.data.success}</p></div>`;
        form.querySelector('.js-form-response').innerHTML = successMsg;
        form.reset();

        const recaptcha = document.getElementById('recaptcha-small-business-signup');
        const btn = form.querySelector('.btn');

        recaptcha.style.display = 'none';
        btn.style.display = 'none';

        trackEvent('Small Business Signup Form', 'Form Submit', 'Small Business Signup Successful Submit');

        setTimeout(() => {
            window.location.replace('/for-small-business/thankyou');
        }, 7000);
    });

    form.addEventListener('error', (event) => {
        const data = event.data;

        if (parseInt(form.querySelector('[name="number_of_employees"]').value) > 150) {
            form.querySelector('.js-employee-error').innerHTML = '<div class="warning-box">Looks like your group is too big for our Small Business plan. Visit our <a href="/pricing">pricing page</a> to find a plan that’s right for you.</div>';
        } else {
            form.querySelector('.js-employee-error').innerHTML = '';
        }

        if (data.hasOwnProperty('paymentErrors')) {
            const errors = data.paymentErrors;
            let errorMsg = '<div class="error-box"><ul>';

            Object.keys(errors).forEach((key) => {
                errorMsg += `<li>${errors[key]}</li>`;
            });

            errorMsg += '</ul></div>';

            form.querySelector('.js-form-response').innerHTML = errorMsg;
        } else if (data.status && data.status == 429) {
            let errorMsg = `<div class="error-box u-margin--t-m"><ul><li>The web site is currently busy, please try again soon.</li></ul></div>`;

            form.querySelector('.js-form-response').innerHTML = errorMsg;
        }
    });
}

function checkCoupon (event) {
    event.preventDefault();

    const smallBusinessPricing = document.querySelector('[data-element="small-business-price"]');
    const couponError = document.querySelector('[data-element="coupon-code-error"]');

    const originalHTML = `
    <h3>Today’s Payment</h3>
    <p class="monthly-price">$150.00</p>
    <p class="note">Your card will be charged $150.00 per month. You may cancel at any time.</p>
    `;

    const coupon_code = document.querySelector('[name="coupon"]').value;

    const data = {
        coupon_code
    };

    axios.post(`${config.API_BASE_ROUTE}small-business/check-coupon`, data)
        .then(({ data }) => {
            // Remove an error if there is one
            couponError.innerHTML = '';

            smallBusinessPricing.classList.add('fade-out');

            // Build success message
            const newHTML =
            `<h3>Today’s Payment</h3>
            <p class="old-monthly-price">$150.00</p>
            <p class="monthly-price">$${data.newAmount}</p>
            <p class="note note-green">${data.message}</p>
            `;

            setTimeout(() => {
                smallBusinessPricing.innerHTML = newHTML;
                smallBusinessPricing.classList.remove('fade-out');
            }, 350);
        }).catch(({ response }) => {
            const data = response.data;

            couponError.innerHTML = `<div class="error-box u-margin--t-m">${data.error}</div>`;

            // Revert to original HTML
            smallBusinessPricing.innerHTML = originalHTML;
        });
}

const formSmallBusiness = {
    init () {
        const form = document.querySelector('[name="small_business_signup"]');

        if (!form) {
            return;
        }

        // Set up phone number formatting
        formatPhoneNumber(form);

        document.querySelector('[data-element="coupon-code-apply"]').addEventListener('click', checkCoupon);

        // Add employee number error div
        const employeeNum = document.createElement('div');
        employeeNum.classList.add('js-employee-error');
        form.appendChild(employeeNum);

        // Add generic form response div
        const response = document.createElement('div');
        response.classList.add('js-form-response');
        form.appendChild(response);

        form.addEventListener('submit', onSubmit);
    }
};

export default formSmallBusiness;
