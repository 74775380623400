/**
 * @description
 *  Initialize general purpose accordions
 */

import { Accordion } from 'abenity-core-js/lib/accordion.js';

const accordions = {
    init () {
        /**
         * General purpose accordions
         */
        if (document.querySelector('[data-element="accordion-btn"]')) {
            const accordion = new Accordion({
                trigger: '[data-element="accordion-btn"]'
            });

            accordion.init();
        }
    }
};

export default accordions;
