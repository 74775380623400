/**
 * @description
 * Show Exit Intent modal if it's container is on the page
 * and it has been shown less than three times
 */

import { DialogTrigger, ExitIntentModal } from '../lib/dialog-trigger.js';
import { renderRecaptcha } from '../lib/recaptcha.js';

import requestDemoForm from './request-demo-form.js';
import formPerksComparison from './form-perks-comparison.js';

const exitIntentModals = {
    init () {
        const modalElement = document.querySelector('[data-element="modal-exit"]');

        if (!modalElement) {
            return;
        }

        const name = modalElement.getAttribute('data-name');

        var cookie = 'abenityExitIntent';
        if (name == 'Perks Comparison') {
            cookie += 'Perks';
        } else if (name == 'Request Demo') {
            cookie += 'Demo';
        }

        /**
         * Init Modal Class
         */
        const modal = new ExitIntentModal({
            modal: modalElement,
            url: modalElement.getAttribute('data-url'),
            name: name
        });

        modal.init();

        /**
         * Listen for content to be finished loading
         * and init form handling JS
         */
        modalElement.addEventListener('modal:contentloaded', (event) => {
            const form = modalElement.querySelector('form');
            renderRecaptcha(form);

            if (event.detail && event.detail.modal) {
                switch (event.detail.modal) {
                case 'request-demo':
                    requestDemoForm.init(form);
                    break;

                case 'perks-comparison-form':
                    formPerksComparison.init(form);
                    break;
                }
            }
        });

        /**
         * Init Dialog Trigger events to show modal
         */
        const exitIntent = new DialogTrigger({
            trigger: 'all',
            timeoutMs: 10000,
            cookie: cookie
        }, (type) => {
            modal.showModal(type);
        });

        exitIntent.init();
    }
};

export default exitIntentModals;
