/**
 *
 * Search nearby members within a range of a postal code
 * /merchants
 *
 * /api/v2/members-in-range?country=US&postal_code=37075&range=10
 *
 */

import axios from '../lib/axios-auth.js';
import config from '../lib/config.js';
import { hideErrors, showErrors } from '../lib/utils.js';
import { numberWithCommas } from '../lib/utils.js';
import { trackEvent } from '../lib/event-tracking.js';

const formSubmit = (event) => {
    event.preventDefault();

    const form = event.target;
    const formResponse = form.querySelector('[data-element="form-response"]');
    const rangeInput = document.querySelector(('[name="range"]'));
    const postalCodeInput = document.querySelector(('[name="postal_code"]'));

    if (postalCodeInput.value === '') {
        formResponse.innerHTML = '<div class="error-box u-margin--t-m">Please enter a zip code.</div>';

        return;
    }

    const range = rangeInput.value;
    const postalCode = postalCodeInput.value;
    const url = `${config.API_BASE_ROUTE}members-in-range?country=US&postal_code=${postalCode}&range=${range}`;

    axios.get(url).then(({ data }) => {
        // Hide possible errors from previous submissions
        hideErrors(form);

        const numMembers = numberWithCommas(data.memberCount);
        const verb = (data.memberCount > 1 || data.memberCount == 0) ? 'are' : 'is';
        const members = (data.memberCount > 1 || data.memberCount == 0) ? 'members' : 'member';
        formResponse.innerHTML = `<div class="success-box u-margin--t-m">There ${verb} ${numMembers} ${members} near ${postalCode}.`;

        trackEvent('Merchant Zip Code Search Form', 'Form Submit', 'Merchant Zip Code Successful Submit');
    }).catch(({ response }) => {
        console.log('here');
        const data = response.data;

        let errorsHTML = '';

        if (Object.prototype.hasOwnProperty.call(data, 'errors') || Object.prototype.hasOwnProperty.call(data, 'error')) {
            if (Object.prototype.hasOwnProperty.call(data, 'errors')) {
                const errors = data.errors;
                errorsHTML += '<ul>';

                Object.keys(errors).forEach(function (item) {
                    errorsHTML += `<li>${errors[item][0]}</li>`;
                });

                errorsHTML += '</ul>';

                // Hide possible errors from previous submissions
                hideErrors(form);
                showErrors(form, errors);
            }

            if (Object.prototype.hasOwnProperty.call(data, 'error')) {
                errorsHTML += `<p>${data.error}</p>`;
            }
        } else {
            errorsHTML = '<p>There was an error. Please refresh the page and try again.</p>';
        }

        formResponse.innerHTML = `<div class="error-box u-margin--t-m">${errorsHTML}</div>`;
    });
};

const addEventListeners = (form) => {
    form.addEventListener('submit', formSubmit);
};

const membersInRange = {
    init () {
        const form = document.querySelector('[data-element="members-in-range-form"]');

        if (form) {
            addEventListeners(form);
        }
    }
};

export default membersInRange;
