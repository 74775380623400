import config from '../lib/config.js';
import { onFormSubmit } from '../lib/form-submit.js';
import { trackEvent } from '../lib/event-tracking.js';

function onSubmit (event) {
    event.preventDefault();

    const form = event.target;

    onFormSubmit(form, `${config.API_BASE_ROUTE}small-business/referral`);

    form.addEventListener('success', (event) => {
        const successMsg = `<div class="success-box u-margin--t-m"><p>${event.data.success}</p></div>`;
        form.querySelector('.js-form-response').innerHTML = successMsg;
        form.reset();

        trackEvent('Small Business Referral Form', 'Form Submit', 'Small Business Referral Successful Submit');
    });
}

const formSmallBusinessReferral = {
    init () {
        const form = document.querySelector('[name="small_business_referral"]');

        if (!form) {
            return;
        }

        // Add form response div
        const response = document.createElement('div');
        response.classList.add('js-form-response');
        form.appendChild(response);

        form.addEventListener('submit', onSubmit);
    }
};

export default formSmallBusinessReferral;
