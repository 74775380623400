/**
 * The typewriter effect on the home page
 * @param {HTMLElement} - the element to change text inside
 * @param {toRotate} - an Array stored on a data element of words to cycle through
 * @param {duration} (optional) - number of milliseconds to wait between changing words
 */

class Typewriter {
    constructor (el, toRotate, duration) {
        this.el = el;
        this.toRotate = toRotate;
        this.duration = parseInt(duration, 10) || 2000;
        this.loopNum = 0;
        this.isDeleting = false;
        this.txt = '';
    }

    init () {
        this.tick();
    }

    tick () {
        const i = this.loopNum % this.toRotate.length;
        const fullTxt = this.toRotate[i];

        // If you're deleting go to previous letter, otherwise go to next letter
        if (this.isDeleting) {
            this.txt = fullTxt.substring(0, this.txt.length - 1);
        } else {
            this.txt = fullTxt.substring(0, this.txt.length + 1);
        }

        this.el.innerHTML = `<span class="wrap">${this.txt}</span>`;

        // Vary speed of typing/deleting
        let delta = 200 - Math.random() * 100;

        if (this.isDeleting) {
            delta /= 2;
        }

        // If the word is fully typed out, switch to deleting letters
        if (!this.isDeleting && this.txt === fullTxt) {
            delta = this.duration;
            this.isDeleting = true;

        // If fully deleted, turn off deleting and advance to next word
        } else if (this.isDeleting && this.txt === '') {
            this.isDeleting = false;
            this.loopNum++;
            delta = 500;
        }

        // Repeat until the last item is typed out
        if (!((this.loopNum + 1 == this.toRotate.length) && this.isDeleting)) {
            setTimeout(() => {
                this.tick();
            }, delta);
        }
    }
}

export default Typewriter;
