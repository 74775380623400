// -- Abenity Core
import { PLATFORM } from 'abenity-core-js/lib/supports.js';
import { addClass, toggleClass } from 'abenity-core-js/lib/classList';

// Local
import { Modal } from '../lib/modal.js';
import { trackEvent } from '../lib/event-tracking.js';

// -- NPM Packages
import tippy from 'tippy.js';
import 'tippy.js/themes/light.css';

const inputFns = {
    'field_1.2': function () {
        const field1 = getInputValue('field_1.1');
        const field2 = getInputValue('field_1.2');
        const savings = (field1 * (field2 * 40)) * 0.15;

        updateFieldTotal('field_1.2', savings);

        return savings;
    },
    'field_1.3': function () {
        const savings = multiplyFields('field_1.1', 'field_1.3', 0.15);
        updateFieldTotal('field_1.3', savings);

        return savings;
    },
    'field_1.4': function () {
        const savings = multiplyFields('field_1.1', 'field_1.4', (0.15 * 120));
        updateFieldTotal('field_1.4', savings);

        return savings;
    },
    'field_2.1': function () {
        const field = parseInt(document.querySelector('.js-savings-calculator').querySelector('[name="field_2.1"]').value);
        const savings = field * 3221;

        updateFieldTotal('field_2.1', savings);

        return savings;
    },
    'field_3.2': function () {
        const field1 = getInputValue('field_3.1');
        const field2 = getInputValuePercent('field_3.2');
        const savings = field1 * 75 * field2;

        updateFieldTotal('field_3.2', savings);

        return savings;
    },
    'field_3.3': function () {
        const field1 = getInputValue('field_3.1');
        const field2 = getInputValuePercent('field_3.3');
        const savings = field1 * 125 * field2;

        updateFieldTotal('field_3.3', savings);

        return savings;
    },
    'field_3.4': function () {
        const field1 = getInputValue('field_3.1');
        const field2 = getInputValuePercent('field_3.4');
        const savings = field1 * 150 * field2;

        updateFieldTotal('field_3.4', savings);

        return savings;
    },
    'field_4.1': function () {
        const field = parseInt(document.querySelector('.js-savings-calculator').querySelector('[name="field_4.1"]').value);
        const savings = field * 0.25;

        updateFieldTotal('field_4.1', savings);

        return savings;
    },
    'field_5.1': function () {
        const field = getInputValue('field_5.1');
        const savings = field * 20;

        updateFieldTotal('field_5.1', savings);

        return savings;
    },
    'field_6.1': function () {
        const field = getInputValue('field_6.1');
        const savings = field * 5;

        updateFieldTotal('field_6.1', savings);

        return savings;
    },
    'field_7.1': function () {
        const field = getInputValue('field_7.1');
        const savings = field * 4;

        updateFieldTotal('field_7.1', savings);

        return savings;
    },
    'field_8.1': function () {
        const field = getInputValue('field_8.1');
        const savings = field * 72;

        updateFieldTotal('field_8.1', savings);

        return savings;
    },
    'field_9.1': function () {
        const field = getInputValue('field_9.1');
        const savings = field * 0.10 * 75;

        updateFieldTotal('field_9.1', savings);

        return savings;
    },
    'field_12.1': function () {
        const savings = multiplyFields('field_11.1', 'field_12.1', 0.25);

        updateFieldTotal('field_12.1', savings);

        return savings;
    },
    'field_14.1': function () {
        const savings = multiplyFields('field_13.1', 'field_14.1', 0.25);

        updateFieldTotal('field_14.1', savings);

        return savings;
    },
    'field_16.1': function () {
        const savings = multiplyFields('field_15.1', 'field_16.1', 0.25);

        updateFieldTotal('field_16.1', savings);

        return savings;
    },
    'field_17.1': function () {
        const field = getInputValue('field_17.1');
        const savings = field * 0.25;

        updateFieldTotal('field_17.1', savings);

        return savings;
    },
    'field_18.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_18.1"]').value;
        const savings = (field === 'yes') ? 120 : 0;

        updateFieldTotal('field_18.1', savings);

        return savings;
    },
    'field_19.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_19.1"]').value;
        const savings = (field === 'yes') ? 250 : 0;

        updateFieldTotal('field_19.1', savings);

        return savings;
    },
    'field_20.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_20.1"]').value;
        const savings = (field === 'yes') ? 500 : 0;

        updateFieldTotal('field_20.1', savings);

        return savings;
    },
    'field_21.1': function () {
        const field = getInputValue('field_21.1');
        const savings = field * 0.15;

        updateFieldTotal('field_21.1', savings);

        return savings;
    },
    'field_22.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_22.1"]').value;
        const savings = (field === 'yes') ? 10 : 0;

        updateFieldTotal('field_22.1', savings);

        return savings;
    },
    'field_23.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_23.1"]').value;
        const savings = (field === 'yes') ? 50 : 0;

        updateFieldTotal('field_23.1', savings);

        return savings;
    },
    'field_24.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_24.1"]').value;
        const savings = (field === 'personal_accountant') ? 50 : 0;

        updateFieldTotal('field_24.1', savings);

        return savings;
    },
    'field_25.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_25.1"]').value;
        const savings = (field === 'yes') ? 500 : 0;

        updateFieldTotal('field_25.1', savings);

        return savings;
    },
    'field_26.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_26.1"]').value;
        const savings = (field === 'yes') ? 1000 * 0.15 : 0;

        updateFieldTotal('field_26.1', savings);

        return savings;
    },
    'field_27.1': function () {
        const field = getInputValue('field_27.1');
        const savings = field * 0.15;

        updateFieldTotal('field_27.1', savings);

        return savings;
    },
    'field_28.1': function () {
        const field = getInputValue('field_28.1');
        const savings = field * 0.2;

        updateFieldTotal('field_28.1', savings);

        return savings;
    },
    'field_29.1': function () {
        const field = getInputValue('field_29.1');
        const savings = field * 100 * 0.3;

        updateFieldTotal('field_29.1', savings);

        return savings;
    },
    'field_29.2': function () {
        const field = getInputValue('field_29.2');
        const savings = field * 0.2;

        updateFieldTotal('field_29.2', savings);

        return savings;
    },
    'field_30.1': function () {
        const field = getInputValue('field_30.1');
        const savings = field * 0.4;

        updateFieldTotal('field_30.1', savings);

        return savings;
    },
    'field_32.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_32.1"]').value;
        const savings = (field === 'yes') ? 50 : 0;

        updateFieldTotal('field_32.1', savings);

        return savings;
    },
    'field_33.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_33.1"]').value;
        const savings = (field === 'no') ? 25 : 0;

        updateFieldTotal('field_33.1', savings);

        return savings;
    },
    'field_34.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_34.1"]').value;
        const savings = (field === 'no') ? 700 : 0;

        updateFieldTotal('field_34.1', savings);

        return savings;
    },
    'field_35.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_35.1"]').value;
        const savings = (field === 'yes') ? 60 : 0;

        updateFieldTotal('field_35.1', savings);

        return savings;
    },
    'field_36.1': function () {
        const field = document.querySelector('.js-savings-calculator').querySelector('[name="field_36.1"]').value;
        const savings = (field === 'yes') ? 22 : 0;

        updateFieldTotal('field_36.1', savings);

        return savings;
    },
    'field_37.1': function () {
        const field = getInputValue('field_37.1');
        const savings = field * 0.1 * 52;

        updateFieldTotal('field_37.1', savings);

        return savings;
    },
    'field_38.1': function () {
        const field = getInputValue('field_38.1');
        const savings = field * 0.1 * 52;

        updateFieldTotal('field_38.1', savings);

        return savings;
    },
    'field_39.1': function () {
        const field = getInputValue('field_39.1');
        const savings = field * 0.25 * 12;

        updateFieldTotal('field_39.1', savings);

        return savings;
    }
};

function updateFieldTotal (field, savings) {
    const value = Math.ceil(savings);
    const formattedSavings = formatTotal(value);

    document.querySelector('[data-sub-total="' + field + '"]').innerHTML = '$' + formattedSavings;
}

function updateTotal () {
    let total = 0;

    document.querySelector('.js-savings-calculator').querySelectorAll('.js-savings-body').forEach(function (el, index) {
        let subTotal = 0;

        el.querySelectorAll('input').forEach(function (input) {
            const name = input.getAttribute('name');
            if (inputFns[name]) {
                const value = Math.ceil(inputFns[name]());

                subTotal += value;
                total += value;
            }
        });

        el.querySelectorAll('select').forEach(function (select) {
            const name = select.getAttribute('name');
            if (inputFns[name]) {
                const value = Math.ceil(inputFns[name]());

                subTotal += value;
                total += value;
            }
        });

        const finalSubTotal = formatTotal(subTotal);
        document.querySelector(`.js-sub-total-${index}`).textContent = finalSubTotal;
    });

    const finalTotal = formatTotal(total);

    document.querySelector('#total_savings').textContent = finalTotal;

    // Google Analytics event
    trackEvent('Savings Calculator', 'Total Savings Updated', '');
}

function formatTotal (total) {
    // Format to currency, with no decimal places or dollar symbol
    return parseFloat(total).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).replace('$', '');
}

function getInputValue (field) {
    return parseInt(document.querySelector('.js-savings-calculator').querySelector('[name="' + field + '"]').value);
}

function getInputValuePercent (field) {
    field = getInputValue(field);

    return field / 100.0;
}

function multiplyFields (field1, field2, savings) {
    field1 = getInputValue(field1);
    field2 = getInputValue(field2);

    return (field1 * field2) * savings;
}

function toggleSections (event, index) {
    event.preventDefault();
    const btn = event.target.closest(`.js-savings-header-${index}`);
    const body = document.querySelector(`.js-savings-body-${index}`);

    toggleClass(body, 'is-visible');
    toggleClass(btn, 'is-visible');
}

function calcInit () {
    const $jsSavingsCalculator = document.querySelector('.js-savings-calculator');

    if (PLATFORM.mobile) {
        addClass($jsSavingsCalculator, 'has-touch');
    }

    $jsSavingsCalculator.querySelectorAll('.js-savings-header').forEach((el, index) => {
        el.addEventListener('click', () => { toggleSections(event, index); });
    });

    $jsSavingsCalculator.querySelectorAll('input').forEach(el => {
        el.addEventListener('change', updateTotal);
    });

    $jsSavingsCalculator.querySelectorAll('select').forEach(el => {
        el.addEventListener('change', updateTotal);
    });

    updateTotal();
}

function tooltipInit () {
    const tippyInstance = tippy('[data-vendor-tooltip]', {
        content: (reference) => {
            const tooltipId = reference.getAttribute('data-vendor-tooltip');

            return document.querySelector(`[data-vendor-tooltip-id="${tooltipId}"]`).innerHTML;
        },
        allowHTML: true,
        appendTo: document.body,
        theme: 'light'
    });
}

function modalInit () {
    const modalTriggers = document.querySelectorAll('[data-element="modal-savings-calc-trigger"]');
    const savingsCalcModal = document.querySelector('[data-element="modal-savings-calc"]');

    if (modalTriggers && savingsCalcModal) {
        const modal = new Modal({
            trigger: modalTriggers,
            modal: '[data-element="modal-savings-calc"]',
            name: 'Savings Calculator'
        });

        modal.init();

        // Init script after HTML is inserted
        modalTriggers.forEach(trigger => {
            trigger.addEventListener('modal:contentloaded', () => {
                calcInit();
                tooltipInit();
            });
        });
    }
}

const savingsCalculator = {
    init () {
        const $jsSavingsCalculator = document.querySelector('.js-savings-calculator');

        if ($jsSavingsCalculator) {
            // If on the Savings Calculator page
            calcInit();
            tooltipInit();
        } else {
            modalInit();
        }
    }
};

export default savingsCalculator;
