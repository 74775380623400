import { resetRecaptchas } from './recaptcha.js';
import axios from './axios-auth.js';
import { hideErrors, showErrors } from './utils.js';

const onFormSubmit = (form, url) => {
    const responseDiv = form.querySelector('.js-form-response');
    const btn = form.querySelector('[type="submit"]');
    const btnText = btn.innerHTML;

    btn.setAttribute('disabled', true);
    btn.textContent = 'Sending...';

    var formData = new FormData(form);

    axios.post(url, formData)
        .then(({ data }) => {
            // Hide possible errors from previous submissions
            hideErrors(form);

            var event = new Event('success');
            event.data = data;

            form.dispatchEvent(event);
        }).catch(({ response }) => {
            let data = response.data;

            if (data.hasOwnProperty('errors')) {
                let errors = data.errors;
                let errorMsg = '<div class="error-box u-margin--t-m"><ul>';

                Object.keys(errors).forEach((key) => {
                    errorMsg += `<li>${errors[key][0]}</li>`;
                });

                errorMsg += '</ul></div>';
                responseDiv.innerHTML = errorMsg;

                // Hide possible errors from previous submissions
                hideErrors(form);

                // Add error class to form items
                showErrors(form, errors);
            } else if (data.hasOwnProperty('error')) {
                let errorMsg = `<div class="error-box u-margin--t-m"><ul><li>${data.error}</li></ul></div>`;

                responseDiv.innerHTML = errorMsg;

                // Hide possible errors from previous submissions
                hideErrors(form);
            } else if (data.status && data.status == 429) {
                let errorMsg = `<div class="error-box u-margin--t-m"><ul><li>The web site is currently busy, please try again soon.</li></ul></div>`;

                responseDiv.innerHTML = errorMsg;

                // Hide possible errors from previous submissions
                hideErrors(form);
            }

            var event = new Event('error');
            event.data = data;
            form.dispatchEvent(event);
        }).finally(() => {
            btn.removeAttribute('disabled');
            btn.innerHTML = btnText;

            resetRecaptchas();
        });
};

export { onFormSubmit };
