// -- NPM Packages
import Cookies from 'js-cookie';

import { trackEvent } from '../lib/event-tracking.js';

const closeNotification = (event, notification) => {
    event.preventDefault();

    Cookies.set('abenityPrivacyDismissal', 'true', { secure: true });
    notification.setAttribute('aria-hidden', true);

    // Google Analytics event tracking
    trackEvent('Cookie Policy', 'Close Cookie Policy Popup', 'Dismiss Cookie Policy');
};

const acceptNotification = (event, notification) => {
    event.preventDefault();

    Cookies.set('abenityPrivacyDismissal', 'true', { expires: 365, secure: true });
    notification.setAttribute('aria-hidden', true);

    trackEvent('Cookie Policy', 'Close Cookie Policy Popup', 'Accept Cookie Policy');
};

let lastKnownScrollPosition = 0;
let ticking = false;

const onScroll = () => {
    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(() => {
            revealNotification(lastKnownScrollPosition);
            ticking = false;
        });

        ticking = true;
    }
};

const revealNotification = (currentScrollY) => {
    const notification = document.querySelector('[data-element="privacy-notice"]');

    if (notification && currentScrollY > 1) {
        notification.setAttribute('aria-hidden', false);

        document.removeEventListener('scroll', onScroll);
    }
};

const privacyNotice = {
    init () {
        if (Cookies.get('abenityPrivacyDismissal') == 'true') {
            return;
        }

        document.addEventListener('scroll', onScroll);

        const notification = document.querySelector('[data-element="privacy-notice"]');
        const noticeClose = document.querySelector('[data-element="privacy-notice-close"]');
        const noticeAccept = document.querySelector('[data-element="privacy-notice-accept"]');

        if (noticeClose && notification) {
            noticeClose.addEventListener('click', (event) => closeNotification(event, notification));
        }

        if (noticeAccept && notification) {
            noticeAccept.addEventListener('click', (event) => acceptNotification(event, notification));
        }
    }
};

export default privacyNotice;
