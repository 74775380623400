import { Modal } from '../lib/modal.js';
import { addClass, removeClass } from 'abenity-core-js/lib/classList';
import { renderRecaptcha } from '../lib/recaptcha.js';
import requestDemoForm from './request-demo-form.js';
import formPerksComparison from './form-perks-comparison.js';
import formSignup from './form-signup.js';

const initOurStoryModal = () => {
    const modalTriggers = document.querySelectorAll('[data-element="modal-our-story-trigger"]');
    const ourStoryModal = document.querySelector('[data-element="modal-our-story"]');

    if (ourStoryModal) {
        const modal = new Modal({
            trigger: modalTriggers,
            modal: ourStoryModal,
            name: 'Our Story Video'
        });

        modal.init();

        /**
         * Lazyload Wistia video
         */
        const modalContent = ourStoryModal.querySelector('[data-element="modal-wistia-video"]');
        const videoEmbed = '<div class="wistia_embed wistia_async_2pluxoptwr seo=false autoPlay=true videoFoam=true" style="height:100%;width:100%">&nbsp;</div>';

        modalTriggers.forEach(trigger => {
            trigger.addEventListener('modal:shown', () => {
                modalContent.innerHTML = videoEmbed;
            });
        });

        /**
         * Open modal from hash
         */
        if (window.location.hash === '#our-story') {
            // Remove autoPlay from embedded video
            const embed = document.querySelector('.wistia_embed');

            if (embed) {
                removeClass(embed, 'autoPlay=true');
            }

            modalContent.innerHTML = videoEmbed;
            modal.showModal();
        }
    }
};

const initRequestDemoModal = () => {
    const requestDemoTriggers = document.querySelectorAll('[data-element="request-demo-overlay"]');

    if (requestDemoTriggers && requestDemoTriggers.length > 0) {
        const modal = new Modal({
            trigger: '[data-element="request-demo-overlay"]',
            modal: '[data-element="modal-request-demo"]',
            url: `${window.__APP_DIRECTORY__}/request-demo`,
            name: 'Request Demo'
        });

        modal.init();

        /**
         * Populate email field in Request Demo form if it has been filled out
         */
        requestDemoTriggers.forEach(trigger => {
            trigger.addEventListener('modal:contentloaded', () => {
                const input = document.querySelector('[data-request-demo-input="email"]');

                if (input) {
                    const value = input.value;
                    document.querySelector('[data-element="request-demo-email-target"]').value = value;
                }

                const form = document.querySelector('[data-element="modal-request-demo"]').querySelector('[data-element="request-demo-form"]');
                requestDemoForm.init(form);
                renderRecaptcha(form);
            });
        });
    }
};

const initSignupForm = (trigger) => {
    const form = document.querySelector('[name="pricing_signup"]');
    renderRecaptcha(form);

    const planRadio = document.getElementById(trigger.getAttribute('data-plan'));

    if (trigger.hasAttribute('data-plan') && planRadio) {
        planRadio.checked = true;
    }

    formSignup.init();
};

const initPerksComparisonForm = () => {
    const form = document.querySelector('[data-element="modal-url"]').querySelector('[data-element="perks-comparison-form"]');
    renderRecaptcha(form);

    formPerksComparison.init(form);
};

/**
 * Modals where the content is loaded from another page
 */
const initUrlModal = () => {
    const modal = new Modal({
        trigger: '[data-element="modal-open-url"]',
        modal: '[data-element="modal-url"]'
    });

    modal.init();

    const triggers = document.querySelectorAll('[data-element="modal-open-url"]');

    if (triggers) {
        triggers.forEach(trigger => {
            trigger.addEventListener('modal:contentloaded', (event) => {
                if (event.detail && event.detail.modal) {
                    switch (event.detail.modal) {
                    case 'signup-form':
                        initSignupForm(trigger);
                        break;

                    case 'perks-comparison-form':
                        initPerksComparisonForm();
                        break;
                    }
                }
            });
        });
    }
};

const modals = {
    init () {
        initUrlModal();

        initOurStoryModal();
        initRequestDemoModal();
    }
};

export default modals;
