import Cookies from 'js-cookie';

/**
 * Sets the cookie that tracks the Exit Intent Modals to the max needed
 * to prevent it from showing. This is used when completing one of the forms
 * from the modals.
 */
const setMaxCookie = (key) => {
    let cookieName = 'abenityExitIntent' + key;
    let cookieVal = Cookies.get(cookieName);

    // if the cookie has been set previously and is less than 4, set it to 3
    // if the cookie hasn't been set, then set it to 3
    if (cookieVal) {
        cookieVal = parseInt(cookieVal);

        if (cookieVal < 4) {
            Cookies.set(cookieName, 3, { secure: true });
        }
    } else {
        Cookies.set(cookieName, 3, { expires: 30, secure: true });
    }
};

export default setMaxCookie;
