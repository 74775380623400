// -- Abenity Core
import domReady from 'abenity-core-js/lib/dom-ready.js';

// -- Global modules
import './modules/back-to-top.js';
import accordions from './modules/accordions.js';
import exitIntentModals from './modules/exit-intent-modals';
import formAgencySignup from './modules/form-agency-signup.js';
import formPerksComparison from './modules/form-perks-comparison.js';
import formSignup from './modules/form-signup.js';
import formSmallBusiness from './modules/form-small-business.js';
import formSmallBusinessReferral from './modules/form-small-business-referral.js';
import headerScroll from './modules/header-scroll.js';
import homeTypewriter from './modules/home-typewriter.js';
import loginDropdown from './modules/login.js';
import mastheadSlider from './modules/masthead-slider.js';
import membersInRange from './modules/members-in-range.js';
import merchantRequestForm from './modules/merchant-request-form.js';
import modals from './modules/modals.js';
import navigation from './modules/navigation.js';
import popup from './modules/popup.js';
import privacyNotice from './modules/privacy-notice.js';
import addonInput from './modules/addon-input.js';
import savingsNetworkList from './modules/savings-network-list.js';
import responsiveLogos from './modules/responsive-logos.js';
import requestDemoForm from './modules/request-demo-form.js';
import rotator from './modules/rotator.js';
import savingsCalculator from './modules/savings-calculator.js';
import slider from './modules/slider.js';

const init = () => {
    accordions.init();
    addonInput.init();
    exitIntentModals.init();
    formAgencySignup.init();
    formPerksComparison.init();
    formSignup.init();
    formSmallBusiness.init();
    formSmallBusinessReferral.init();
    headerScroll.init();
    homeTypewriter.init();
    loginDropdown.init();
    mastheadSlider.init();
    membersInRange.init();
    merchantRequestForm.init();
    modals.init();
    navigation.init();
    popup.init();
    privacyNotice.init();
    responsiveLogos.init();
    requestDemoForm.init();
    rotator.init();
    savingsCalculator.init();
    slider.init();

    // Initialize savings network wrapper
    const savingsNetworkWrapper = document.querySelector('[data-element="savings-network"');
    if (savingsNetworkWrapper) {
        new savingsNetworkList(savingsNetworkWrapper);
    }
};

domReady().then(init);
