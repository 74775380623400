/**
 * @description Select DOM elements by CSS selector
 * @see {@link https://plainjs.com/javascript/selecting/select-dom-elements-by-css-selector-4/|Plain JS}
 *
 * @example
 * // select all '.bar' elements inside all '.foo' containers
 * var matches = $$('.foo .bar');
 *
 * // context example
 * var container = $('.foo');
 *
 * // select '.bar' elements inside this one container
 * var matches = $('.bar', container);
 */

const d = document;

// Returns all elements that match CSS selector {expr} as an array.
// Querying can optionally be restricted to {container}’s descendants
function $$(expr, container) {
    return [].slice.call((container || d).querySelectorAll(expr));
}

// Returns first element that matches CSS selector {expr}.
// Querying can optionally be restricted to {container}’s descendants
function $(expr, container) {
    return typeof expr === "string"? (container || d).querySelector(expr) : expr || null;
}

export { $$, $ };
