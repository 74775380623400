class Accordion {
    constructor ({
        trigger = '[data-accordion]'
    } = {}) {
        try {
            this.trigger = document.querySelectorAll(trigger);

            if (this.trigger.length < 1) {
                console.error('Accordion: Trigger element not found in document.');
                return;
            }
        } catch (e) {
            console.error('Accordion: Trigger element is not a valid selector');
        }
    }

    init () {
        if (!this.trigger) {
            return;
        }

        this.trigger.forEach(el => {
            el.addEventListener('click', this.toggleSection);
        });
    }

    toggleSection (event) {
        const trigger = event.target.closest('button') || event.target.closest('a');
        const sectionId = trigger.getAttribute('aria-controls');
        const section = document.getElementById(sectionId);

        if (section) {
            const toggle = section.classList.contains('is-visible') ? false : true;

            if (toggle) {
                trigger.classList.add('is-visible');
                trigger.setAttribute('aria-expanded', true);
                section.classList.add('is-visible');
            } else {
                trigger.classList.remove('is-visible');
                trigger.setAttribute('aria-expanded', false);
                section.classList.remove('is-visible');
            }
        } else {
            if (newrelic) {
                newrelic.noticeError(`Accordion Section not found: ${trigger}`);
            }
        }
    }
}

export { Accordion };