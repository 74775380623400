/**
 * @description DOM Ready event tied to a promise
 *
 * @example
 * domReady().then(() => {
 *     console.log('hello there I am ready');
 * });
 *
 * async start () {
 *      await domReady();
 * }
 *
 * start();
 *
 */

export default function domReady () {
    return new Promise(resolve => {
        if (document.readyState == 'loading') {
            document.addEventListener('DOMContentLoaded', resolve);
        } else {
            resolve();
        }
    });
}
