/**
 * Cycle images in a masthead
 */

const mastheadSlider = {
    init () {
        const slides = document.querySelectorAll('[data-element="img-slider"]');

        // Lazysizes was downloading these twice, so lazyloading is being
        // done here instead
        const bgs = document.querySelectorAll('[data-element="img-slider-bg"]');

        bgs.forEach(bg => {
            const img = bg.getAttribute('data-bg');
            bg.setAttribute('style', `background-image: url('${img}');`);
        });

        if (slides && slides.length > 1) {
            let currentSlide = 0;

            function nextSlide () {
                const nextSlide = (currentSlide + 1) % slides.length;

                slides[nextSlide].className = 'masthead__slide is-showing';
                slides[currentSlide].className = 'masthead__slide';

                currentSlide = nextSlide;
            }

            setInterval(nextSlide, 5000);
        }
    }
};

export default mastheadSlider;
