// -- NPM Packages
import Flickity from 'flickity-imagesloaded';

const slider = {
    init () {
        const sliders = document.querySelectorAll('[data-element="slider"]');

        let options = {
            cellAlign: 'center',
            imagesLoaded: true,
            pageDots: false,
            wrapAround: true
        };

        if (sliders) {
            sliders.forEach(slider => {
                if (slider.getAttribute('data-flickity-options')) {
                    options = JSON.parse(slider.getAttribute('data-flickity-options'));
                }

                const flkty = new Flickity(slider, options);
            });
        }
    }
};

export default slider;
