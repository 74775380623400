import triggerEvent from 'abenity-core-js/lib/trigger-event.js';

/**
 * @param {String} trigger - the selector for the element that triggers the navigation to open
 * @param {String} navigation - the selector for the containing element of the navigation that
 *                              will be toggled visible
 * @param {String} closeBtn - the selector for a close button for the navigation
 * @param {String} canvasClose - the selector for an element that fills the screen in the background
 *                               and can toggle the menu's visibility
 * @param {String} bodyClass - an optional name for the class assigned to the body when the menu
 *                             is opened
 */
class Menu {
    constructor ({
        name,
        trigger,
        navigation,
        closeBtn,
        canvasClose,
        bodyClass
    } = {}) {
        // UI State
        this.state = {
            isVisible: false
        };
        this.scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

        // Used for Google Analytics event
        this.name = name || '';

        // Selectors
        this.triggers = document.querySelectorAll(trigger);
        this.navigation = document.querySelector(navigation);
        this.closeBtn = document.querySelector(closeBtn);
        this.canvasClose = document.querySelector(canvasClose);
        this.body = document.body;
        this.bodyClass = bodyClass || 'has-menu';

        this.validateElements({
            'trigger': this.triggers,
            'navigation': this.navigation,
            'close button': this.closeBtn,
            'canvas close': this.canvasClose
        });

        // Pre bind reference to function
        this.keyEvent = this.keyEvent.bind(this);
    }

    init () {
        this.triggers.forEach(el => {
            el.addEventListener('click', (event) => this.showMenu(event));
        });

        this.closeBtn.addEventListener('click', (event) => this.toggleMenu(event));
        this.canvasClose.addEventListener('click', (event) => this.toggleMenu(event));
        this.navigation.addEventListener('transitionend', () => this.onTransitionEnd());

        // Prevent event bubbling
        this.navigation.addEventListener('click', (event) => event.stopPropagation());

        // Close menu and redirect if submenu link with anchor is clicked
        const sublinks = document.querySelectorAll('.mobile-nav__subnav .mobile-nav__link');
        sublinks.forEach((sublink) => {
            const sublinkHref = sublink.getAttribute('href');

            if (sublinkHref.includes('#')) {
                sublink.addEventListener('click', (event) => {
                    // Set flag to prevent resetting focus to menu trigger in site header
                    this.preventResetFocus = true;

                    this.toggleMenu(event);
                    window.location.href = sublinkHref;
                });
            }
        });
    }

    /**
     * Use escape key to close the menu
     * @param {Event} event - key event
     */
    keyEvent (event) {
        const key = event.key;

        if (key === 'Escape') {
            this.toggleMenu();
        }
    }

    /**
     *
     * @param {Array} elements - an array of DOM Nodes
     * @description - Validate the presence of required elements for the Menu to function
     */
    validateElements (elements) {
        Object.keys(elements).forEach(key => {
            if (!elements[key]) {
                console.error(`Menu Error: \u2757 Missing element %c'${elements[key]}'`, 'font-weight: bold;');
            }
        });
    }

    onTransitionEnd () {
        // If state is not visible, we are hiding
        if (this.state.isVisible == false) {
            // Remove `display: block` class
            // and set aria-hidden attribute
            if (this.currentTrigger) {
                this.currentTrigger.classList.remove('is-active');
            }

            this.navigation.classList.remove('is-active');
            this.canvasClose.classList.remove('is-active');
            this.navigation.setAttribute('aria-hidden', true);
            this.canvasClose.setAttribute('aria-hidden', true);

            // remove overflow: hidden
            // this.body.style.paddingRight = 0;
            this.body.classList.remove(this.bodyClass);

            // Remove expanded and set focus back to trigger
            // that launched the menu
            if (this.currentTrigger) {
                this.currentTrigger.setAttribute('aria-expanded', false);

                if (!this.preventResetFocus) {
                    this.currentTrigger.focus();
                }

                this.currentTrigger = null;
            }

            // Clean up key event for esc key
            document.removeEventListener('keydown', this.keyEvent);
        } else {
            // Set up key event for esc key
            document.addEventListener('keydown', this.keyEvent);
        }
    }

    showMenu (event) {
        event.preventDefault();

        // store the current trigger
        this.currentTrigger = event.target.closest('a') || event.target.closest('button');
        this.toggleMenu();
    }

    toggleMenu (event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this.state.isVisible) {
            this.state.isVisible = false;

            // Removing transition class for animation
            // will trigger transitionend event listener and onTransitionEnd()
            this.navigation.classList.remove('is-visible');
            this.canvasClose.classList.remove('is-visible');
        } else {
            this.state.isVisible = true;

            // Add class to prevent overflow scrolling
            this.body.classList.add(this.bodyClass);
            // this.body.style.paddingRight = this.scrollBarWidth + 'px';

            // Add the `display: block` class
            this.navigation.classList.add('is-active');
            this.canvasClose.classList.add('is-active');
            this.navigation.setAttribute('aria-hidden', false);
            this.canvasClose.setAttribute('aria-hidden', false);

            // Set expanded to true and focus on flyout menu for tabindex
            if (this.currentTrigger) {
                this.currentTrigger.setAttribute('aria-expanded', true);
            }

            this.navigation.focus();

            // Slight delay after display: block, so the animation can be seen
            setTimeout(() => {
                // Add animation classes
                this.navigation.classList.add('is-visible');
                this.canvasClose.classList.add('is-visible');

                if (this.currentTrigger) {
                    triggerEvent(this.currentTrigger, 'menu:shown');
                }
            }, 25);
        }
    }
}

export { Menu };
