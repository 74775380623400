import SUPPORTS from '../lib/supports.js';
import Typewriter from '../lib/typewriter.js';

const setupObserver = element => {
    const config = {
        root: null,
        rootMargin: '0px 0px -50px'
    };

    const observer = new IntersectionObserver(function (entries, self) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                const toRotate = entry.target.getAttribute('data-type');
                const duration = entry.target.getAttribute('data-duration');

                const typewriter = new Typewriter(element, JSON.parse(toRotate), duration);
                typewriter.init();

                self.unobserve(entry.target);
            }
        });
    }, config);

    observer.observe(element);
};

const homeTypewriter = {
    init () {
        const element = document.querySelector('[data-element="typewriter"]');

        /**
         * If IntersectionObserver is supported,
         * start typewriter looping when element is in view
         */
        if (element && SUPPORTS.intersectionObserver) {
            setupObserver(element);
            return;
        }

        if (element) {
            const toRotate = element.getAttribute('data-type');
            const duration = element.getAttribute('data-duration');
            const typewriter = new Typewriter(element, JSON.parse(toRotate), duration);

            typewriter.init();
        }
    }
};

export default homeTypewriter;
