// -- NPM Packages
import tippy from 'tippy.js';
import 'tippy.js/themes/light.css';

import config from '../lib/config.js';
import { onFormSubmit } from '../lib/form-submit.js';
import { trackEvent } from '../lib/event-tracking.js';
import setMaxCookie from './set-max-cookie.js';

const tooltipInit = () => {
    const tippyInstance = tippy('[data-perks-comparison-tooltip]', {
        content: () => {
            return document.getElementById('perks-comparison-terms').innerHTML;
        },
        allowHTML: true,
        appendTo: document.body,
        theme: 'light'
    });
};

function onSubmit (event) {
    event.preventDefault();

    const form = event.target;

    onFormSubmit(form, `${config.API_BASE_ROUTE}perks-comparison`);

    form.addEventListener('success', (event) => {
        const successMsg = `<div class="success-box u-margin--t-m"><p>${event.data.success}</p></div>`;
        form.querySelector('.js-form-response').innerHTML = successMsg;
        form.reset();

        // Prevent future exit intent modals from showing
        setMaxCookie('Perks');
        trackEvent('Perks Comparison Form', 'Form Submit', 'Perks Comparison Successful Submit');

        // LinkedIn tracking
        if (window.lintrk) {
            window.lintrk('track', { conversion_id: 8493356 });
        }
    });
}

const formPerksComparison = {
    init (form = null) {
        if (form) {
            const response = document.createElement('div');
            response.classList.add('js-form-response');
            form.appendChild(response);

            form.addEventListener('submit', onSubmit);
        } else {
            const forms = document.querySelectorAll('[data-element="perks-comparison-form"]');

            if (!forms) {
                return;
            }

            // Add form response div
            forms.forEach(form => {
                const response = document.createElement('div');
                response.classList.add('js-form-response');
                form.appendChild(response);

                form.addEventListener('submit', onSubmit);
            });
        }

        // Init terms tooltip
        tooltipInit();
    }
};

export default formPerksComparison;
