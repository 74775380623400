// -- NPM Packages
import Flickity from 'flickity-imagesloaded';

const responsiveLogos = {
    init () {
        const elems = document.querySelectorAll('[data-element="responsive-logos"]');

        let options = {
            cellAlign: 'center',
            groupCells: 2,
            wrapAround: true,
            imagesLoaded: true,
            pageDots: false,
            watchCSS: true
        };

        if (elems) {
            elems.forEach(elem => {
                if (elem.getAttribute('data-flickity-options')) {
                    options = JSON.parse(elem.getAttribute('data-flickity-options'));
                }

                const flkty = new Flickity(elem, options);
            });
        }
    }
};

export default responsiveLogos;
