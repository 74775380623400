import axios from '../lib/axios-auth.js';
import config from '../lib/config.js';
import { hideErrors, showErrors } from '../lib/utils.js';
import { resetRecaptchas } from '../lib/recaptcha.js';
import { trackEvent } from '../lib/event-tracking.js';

import { formatPhoneNumber } from './phone-formatting.js';

const formSubmit = (event) => {
    event.preventDefault();

    const form = event.target;
    const formResponse = form.querySelector('[data-element="form-response"]');
    const url = `${config.API_BASE_ROUTE}merchant-request`;

    const btn = form.querySelector('[type="submit"]');
    const btnText = btn.innerHTML;

    btn.setAttribute('disabled', true);
    btn.textContent = 'Sending...';

    const formData = new FormData(form);

    axios.post(url, formData).then(({ data }) => {
        // Hide possible errors from previous submissions
        hideErrors(form);

        let successHTML = '';

        if (Object.prototype.hasOwnProperty.call(data, 'success')) {
            successHTML = `<p>${data.success}</p>`;
        } else {
            successHTML = '<p>Thank you for your submission.</p>';
        }

        formResponse.innerHTML = `<div class="success-box u-margin--t-m">${successHTML}</div>`;

        trackEvent('Merchant Request Form', 'Form Submit', 'Merchant Request Successful Submit');
    }).catch(({ response }) => {
        const data = response.data;

        let errorsHTML = '';

        if (Object.prototype.hasOwnProperty.call(data, 'errors') || Object.prototype.hasOwnProperty.call(data, 'error')) {
            if (Object.prototype.hasOwnProperty.call(data, 'errors')) {
                const errors = data.errors;
                errorsHTML += '<ul>';

                Object.keys(errors).forEach(function (item) {
                    errorsHTML += `<li>${errors[item][0]}</li>`;
                });

                errorsHTML += '</ul>';

                // Hide possible errors from previous submissions
                hideErrors(form);
                showErrors(form, errors);
            }

            if (Object.prototype.hasOwnProperty.call(data, 'error')) {
                errorsHTML += `<p>${data.error}</p>`;
            }
        } else {
            errorsHTML = '<p>There was an error. Please refresh the page and try again.</p>';
        }

        formResponse.innerHTML = `<div class="error-box u-margin--t-m">${errorsHTML}</div>`;
    }).finally(() => {
        btn.removeAttribute('disabled');
        btn.innerHTML = btnText;

        resetRecaptchas();
    });
};

const addEventListeners = (form) => {
    form.addEventListener('submit', formSubmit);

    formatPhoneNumber(form);
};

const merchantRequestForm = {
    init () {
        const forms = document.querySelectorAll('[data-element="merchant-request-form"]');

        if (forms) {
            forms.forEach(form => {
                addEventListeners(form);
            });
        }
    }
};

export default merchantRequestForm;
