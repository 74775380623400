// -- NPM Packages
import Flickity from 'flickity-imagesloaded';

const initAutoSlider = (btns) => {
    const btnCount = btns.length;
    let position = 1;

    if (btnCount > 0) {
        setInterval(() => {
            btns[position].click();

            position++;

            if (position >= btnCount) {
                position = 0;
            }
        }, 5000);
    }
};

const initFlickity = (elem) => {
    const flkty = new Flickity(elem, {
        autoPlay: 4000,
        cellAlign: 'center',
        wrapAround: true,
        pageDots: true,
        watchCSS: true
    });
};

const initFlickityCards = (elem) => {
    const flkty = new Flickity(elem, {
        autoPlay: 4000,
        cellAlign: 'center',
        imagesLoaded: true,
        wrapAround: true,
        pageDots: true,
        watchCSS: true
    });

    setTimeout(() => {
        flkty.resize();
    }, 500);
};

const initComparisonSlider = (elem) => {
    const flkty = new Flickity(elem, {
        autoPlay: 4000,
        cellAlign: 'center',
        wrapAround: true,
        pageDots: true,
        imagesLoaded: true,
        group: 3,
        watchCSS: true
    });
};

const clearClasses = (nodelist, elemClass) => {
    nodelist.forEach(node => {
        if (node.classList.contains(elemClass)) {
            node.classList.remove(elemClass);
        }
    });
};

const onClick = (event, index) => {
    const trigger = event.target.closest('button');
    const rotatorBtns = document.querySelectorAll('[data-element="rotator-btn"]');
    const slides = document.querySelectorAll('[data-element="rotator-cell"]');

    clearClasses(rotatorBtns, 'is-active');
    trigger.classList.add('is-active');

    clearClasses(slides, 'is-visible');
    slides[index].classList.add('is-visible');
};

const initRotator = () => {
    const rotatorBtns = document.querySelectorAll('[data-element="rotator-btn"]');

    rotatorBtns.forEach((el, index) => {
        el.addEventListener('click', (event) => onClick(event, index));
    });
};

const rotator = {
    init () {
        const rotatorEl = document.querySelector('[data-element="features-rotator"]');
        const rotatorBtns = document.querySelectorAll('[data-element="rotator-btn"]');
        const comparisonSlider = document.querySelector('[data-element="comparison-slider"]');
        const featureCards = document.querySelector('[data-element="feature-cards-responsive"]');

        if (rotatorEl) {
            initFlickity(rotatorEl);
        }

        if (featureCards) {
            initFlickityCards(featureCards);
        }

        if (rotatorBtns) {
            initRotator();
            initAutoSlider(rotatorBtns);
        }

        if (comparisonSlider) {
            initComparisonSlider(comparisonSlider);
        }
    }
};

export default rotator;
