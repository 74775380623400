/**
 * @param {String} el - the HTMLElement that will trigger the event
 * @param {String} type - the name of the event to trigger
 * @param {Object} params - a list of optional parameters for the event
 *
 * @description Create and trigger an event from an element
 *
 * @example
 * var el = document.querySelector('input[type="text"]');
 * triggerEvent(el, 'mousedown');
 */

export default function triggerEvent (el, type, params = { bubbles: false, cancelable: false, detail: null }) {
    let event;

    if (typeof window.CustomEvent === 'function') {
        // Modern Browsers
        event = new CustomEvent(type, params);
    } else {
        // IE 11
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(type, params.bubbles, params.cancelable, params.detail);
    }

    // Dispatch the event
    el.dispatchEvent(event);
}
