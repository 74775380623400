const onFocus = (event) => {
    event.target.parentNode.classList.add('has-focus');
};

const onBlur = (event) => {
    event.target.parentNode.classList.remove('has-focus');
};

const addonInput = {
    init () {
        const inputs = document.querySelectorAll('[data-element="addon-input"]');

        if (inputs) {
            inputs.forEach(input => {
                input.addEventListener('focus', onFocus);
                input.addEventListener('blur', onBlur);
            });
        }
    }
};

export default addonInput;
