/**
 * @description
 *  Initialize footer accordions in mobile view
 *  and the mobile nav from the mobile nav button
 */

// -- Core
import { Accordion } from 'abenity-core-js/lib/accordion.js';

const navigation = {
    init () {
        /**
        * Footer mobile accordions
        */
        if (document.querySelector('[data-element="footer-accordion"]')) {
            const footerAccordion = new Accordion({ trigger: '[data-element="footer-accordion"]' });
            footerAccordion.init();
        }

        /**
        * Mobile Menu Accordions
        *
        */
        if (document.querySelector('[data-element="mobile-nav-accordion"]')) {
            const mobileAccordion = new Accordion({ trigger: '[data-element="mobile-nav-accordion"]' });
            mobileAccordion.init();
        }
    }
};

export default navigation;
