import config from '../lib/config.js';
import { onFormSubmit } from '../lib/form-submit.js';

import { formatPhoneNumber } from './phone-formatting.js';

const resetForm = (form) => {
    form.reset();
};

const onSubmit = (event) => {
    event.preventDefault();

    const form = event.target;

    onFormSubmit(form, `${config.API_BASE_ROUTE}agency/signup`);

    form.addEventListener('success', (event) => {
        const successMsg = `<div class="success-box u-margin--t-m"><p>${event.data.success}</p></div>`;
        form.querySelector('.js-form-response').innerHTML = successMsg;

        resetForm(form);
    });
};

const formAgencySignup = {
    init () {
        const form = document.querySelector('[name="agency_signup"]');

        if (!form) {
            return;
        }

        // Set up phone number formatting
        formatPhoneNumber(form);

        // Add form response div
        const response = document.createElement('div');
        response.classList.add('js-form-response');
        form.appendChild(response);

        form.addEventListener('submit', onSubmit);
    }
};

export default formAgencySignup;
