/**
 * @description Opens url in a new window
 */

const showPopup = (options) => {
    const w = options.width || 496;
    const h = options.height || 466;
    const name = options.name || '';

    const settings = 'scrollbars=yes, resizable=no, status=no, width='+w+','+'height='+h+',' + 'screenX=' + 0 +','+'screenY=' + 0 + ','+'left=' + 0 + ',' + 'top=' + 0;

    const newWnd = window.open(options.theURL, name, settings);

    // Fixes a vulnerability related to window.opener.location
    // https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
    newWnd.opener = null;
};

const getOptions = (event) => {
    event.preventDefault();

    const $this = event.target.closest('a') || event.target.closest('button');

    const options = {
        theURL: $this.getAttribute('href'),
        width: $this.getAttribute('data-width'),
        height: $this.getAttribute('data-height'),
        name: $this.getAttribute('data-name')
    };

    showPopup(options);
};

const popup = {
    init () {
        const sharingPopups = document.querySelectorAll('[data-element="popup"]');

        sharingPopups.forEach(function(el) {
            el.addEventListener('click', getOptions);
        });
    }
};

export default popup;
