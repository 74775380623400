// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const hideErrors = (form) => {
    const fields = form.querySelectorAll('.has-error');

    if (fields) {
        fields.forEach(field => {
            field.classList.remove('has-error');
        });
    }
};

const showErrors = (form, errors) => {
    const inputs = [];

    Object.keys(errors).forEach(function (item) {
        inputs.push(form.querySelector(`[name="${item}"]`));
    });

    inputs.forEach(input => {
        const field = input.closest('.field');

        if (field) {
            field.classList.add('has-error');
        }
    });
};

export { numberWithCommas, hideErrors, showErrors };
