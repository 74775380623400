import Cookies from 'js-cookie';

import triggerEvent from 'abenity-core-js/lib/trigger-event.js';
import axios from '../lib/axios-auth.js';
import { trackEvent } from '../lib/event-tracking';

/**
 * Summary of Behavior:
 * After 5 seconds on the page, if the mouse cursor moves out of the top of the window toward the location bar, the modal shows.
 * After scrolling down, if you scroll up quickly, the modal shows.
 * You can only see the modal once per page load.
 * If you've see the modal 3 times, you can't see it again for 30 days.
 * A Google Tracking event with its own unique category "Exit Intent Modals" is created when the modal is shown.
 * It also records the interaction that caused the modal to show and which one it is (Perks Comparison or Request Demo).
 *
 * @see
 *      https://medium.com/weekly-webtips/how-to-make-an-effective-exit-intent-popup-in-javascript-bf6051b4a6d4
 *      https://github.com/shahzam/DialogTriggerJS
 */

class DialogTrigger {
    constructor ({
        trigger = 'timeout',
        target = null,
        timeoutMs = 0,
        cookie,
        percentDown = 50, // Used for 'percent' to define a down scroll threshold of significance (based on page height)
        percentUp = 10, // Used for 'percent' to define a threshold of upward scroll after down threshold is reached
        scrollInterval = 1000 // Frequency (in ms) to check for scroll changes (to avoid bogging the UI)
    }, callback) {
        this.complete = false; // for checking if popup has been trigger

        this.timer = null; // store reference for clearing setTimeout
        this.interval = null; // store reference for clearing setInterval

        this.trigger = trigger;
        this.target = target;
        this.timeoutMs = timeoutMs;
        this.cookie = cookie;
        this.percentDown = percentDown;
        this.percentUp = percentUp;
        this.scrollInterval = scrollInterval;

        this.callback = callback;

        // Pre bind reference to function
        this.exitIntentShow = this.exitIntentShow.bind(this);
    }

    init () {
        // If the modal has been seen at least 3 times, don't show it
        if (this.overLimit()) {
            return;
        }

        switch (this.trigger) {
        case 'all':
            this.exitIntent();
            this.scrollUp();
            // this.timeout();
            break;

        case 'exitIntent':
            this.exitIntent();
            break;

        case 'scrollUp':
            this.scrollUp();
            break;

        case 'timeout':
            this.timeout();
            break;
        }
    }

    overLimit () {
        if (!Cookies.get(this.cookie)) {
            return;
        }

        return (parseInt(Cookies.get(this.cookie)) >= 3);
    }

    setCookie () {
        let cookieVal = Cookies.get(this.cookie);

        // if the cookie has been set previously and is less than 4, increment it,
        // otherwise, set it to 1
        if (cookieVal) {
            cookieVal = parseInt(cookieVal);

            if (cookieVal < 4) {
                cookieVal += 1;
                Cookies.set(this.cookie, cookieVal, { secure: true });
            }
        } else {
            Cookies.set(this.cookie, 1, { expires: 30, secure: true });
        }
    }

    /**
     * Add Event Listener for mouseout on the document
     * Used to track if mouse cursor goes out the top of the window
     */
    exitIntent () {
        // Add mouseout event listener after 5 seconds
        let exitListenerTimeout = setTimeout(() => {
            document.addEventListener('mouseout', this.exitIntentShow);
            clearTimeout(exitListenerTimeout);
            exitListenerTimeout = null;
        }, 5000);
    }

    exitIntentShow (event) {
        /**
         *  Check if the modal hasn't been shown yet
         *  and if it isn't current over an element in the body (outside the window)
         *  and if the cursor went above the top of the browser window toward the location bar
         **/
        const show =
            !this.complete &&
            !event.toElement &&
            !event.relatedTarget &&
            event.clientY < 0;

        if (show) {
            this.callback('Location Bar');
            this.completed();
            document.removeEventListener('mouseout', this.exitIntentShow);
        }
    }

    /**
     * If the user has scrolled down for a while, then scrolls up suddenly
     */
    scrollUp () {
        // Let the user scroll down by some significant amount
        let scrollStart = document.documentElement.scrollTop;
        const pageHeight = document.body.clientHeight;

        if (pageHeight > 0) {
            // Only check the scroll position every few seconds, to avoid bogging the UI
            this.interval = setInterval(() => {
                let scrollAmount = scrollStart - document.documentElement.scrollTop;

                if (scrollAmount < 0) {
                    scrollAmount = 0;
                    scrollStart = document.documentElement.scrollTop;
                }

                const upScrollPercent = parseFloat(scrollAmount) / parseFloat(pageHeight);

                if (upScrollPercent > parseFloat(this.percentUp) / 100) {
                    clearInterval(this.interval);
                    this.interval = null;

                    if (!this.complete) {
                        this.callback('Scroll Up');
                        this.completed();
                    }
                }
            }, this.scrollInterval);
        }
    }

    /**
     * A simple setTimeout to show the modal
     */
    timeout () {
        if (!this.complete) {
            this.timer = setTimeout(() => {
                this.completed();
                this.callback('Timeout');

                clearTimeout(this.timer);
                this.timer = null;
            }, this.timeoutMs);
        }
    }

    /**
     * Sets this.complete to true, so the modal only shows once per page session
     * Sets or updates the cookie to avoid someone seeing it more than 3 times in 30 days
     * Clears any timers or intevals from the other functions
     */
    completed () {
        this.complete = true;
        this.setCookie();

        // timeout and scroll interval are no longer needed
        if (this.timer !== null) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        if (this.interval !== null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
}

class ExitIntentModal {
    constructor ({
        modal,
        closeTrigger = '[data-element="modal-close"]',
        modalContent = '[data-element="modal-content"]',
        bodyClass = 'has-modal',
        url = null,
        name = null
    } = {}) {
        // UI State
        this.state = {
            isVisible: false
        };

        // Required Arguments
        this.modal = this.setupElement(modal);

        // Optional Arguments
        this.url = url;
        this.name = name;

        // Used internally
        this.body = document.body;
        this.bodyClass = bodyClass;

        // Selectors scoped to this modal
        this.closeTriggers = this.modal.querySelectorAll(closeTrigger);
        this.modalContent = this.modal.querySelector(modalContent);

        // Pre bind reference to function
        this.keyEvent = this.keyEvent.bind(this);
    }

    init () {
        this.closeTriggers.forEach(el => {
            el.addEventListener('click', (event) => this.onClick(event));
        });

        // Prevent event bubbling from clicks on the modal content
        this.modalContent.addEventListener('click', (event) => event.stopPropagation());
    }

    onClick (event) {
        event.preventDefault();

        // Store the current trigger
        this.state.currentTrigger = event.target.closest('a') || event.target.closest('button') || event.target.closest('[data-element="modal-close"]');

        if (this.state.isVisible === false) {
            this.showModal();
        } else {
            this.closeModal();
        }
    }

    /**
     * Use escape key to close the menu
     * @param {Event} event - key event
     */
    keyEvent (event) {
        const key = event.key;

        if (key === 'Escape') {
            this.closeModal();
        }
    }

    /**
     *
     * @param {String|HTMLElement} element - either a string or an HTMLElement
     * @returns {HTMLElement}
     */
    setupElement (element) {
        return typeof element === 'string' ? document.querySelector(element) : element;
    }

    closeModal () {
        const handler = () => {
            this.state.isVisible = false;

            this.modal.classList.remove('is-active');
            this.modal.setAttribute('aria-hidden', true);

            // remove overflow: hidden
            this.body.classList.remove(this.bodyClass);

            // Empty Modal Content if loading from a url
            if (this.url) {
                this.renderLoadingTemplate();
            }

            // Clean up transitionend event for closing
            this.modal.removeEventListener('transitionend', handler, false);

            // Clean up key event for esc key
            document.removeEventListener('keydown', this.keyEvent);
        };

        // Removing transition class for animation
        // will trigger transitionend event listener
        this.modal.addEventListener('transitionend', handler, false);
        this.modal.classList.remove('is-visible');
    }

    /**
     *
     * @param {String} type - optional argument sent from Dialog Trigger for tracking which exit intent event showed the modal
     */
    showModal (type) {
        this.state.isVisible = true;

        // Add class to prevent overflow scrolling
        this.body.classList.add(this.bodyClass);

        // Add the `display: block` class
        this.modal.classList.add('is-active');
        this.modal.setAttribute('aria-hidden', false);

        this.modal.focus();

        const handler = () => {
            // Set up key event for esc key
            document.addEventListener('keydown', this.keyEvent);

            // Clean up transitionend event
            this.modal.removeEventListener('transitionend', handler, false);
        };

        // Add transitionend event
        this.modal.addEventListener('transitionend', handler, false);

        // Slight delay after display: block, so the animation can be seen
        setTimeout(() => {
            // Add animation classes
            this.modal.classList.add('is-visible');

            // If there is a url passed get the content for the modal
            if (this.url) {
                this.getModalContent();
            }

            let eventType = '';

            if (type) {
                eventType = ` - ${type}`;
            }

            trackEvent('Exit Intent Modals', `Modal Shown${eventType}`, `${this.name} Exit Intent Modal`);
        }, 25);
    }

    getModalContent () {
        axios.get(this.url)
            .then(response => {
                const doc = document.createElement('div');
                doc.innerHTML = response.data;

                this.modalContent.innerHTML = doc.querySelector('[data-element="modal-url-target"]').innerHTML;

                const params = {
                    bubbles: false,
                    cancelable: false,
                    detail: {
                        'modal': this.modal.getAttribute('data-event-params')
                    }
                };

                triggerEvent(this.modal, 'modal:contentloaded', params);
            });
    }

    renderLoadingTemplate () {
        this.modalContent.innerHTML = `
            <div class="loading-animation">
                <p class="hd-26-black u-c--blue u-ta--center u-margin--b-0">Loading</p>
                <div class="loading-animation__circles">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
            </div>
        `;
    }
}

export { DialogTrigger, ExitIntentModal };
