// -- Abenity Core
import { Menu } from '../lib/menu.js';

// -- NPM Packages
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
// import 'tippy.js/themes/light.css';

// -- Local
import SUPPORTS from '../lib/supports.js';

// IntersectionObserver callback
const observeHeader = (entries) => {
    const siteHeader = document.querySelector('[data-element="site-header-clone"]');

    entries.forEach(entry => {
        // Toggle header clone visibility
        if (entry.isIntersecting) {
            siteHeader && siteHeader.classList.remove('is-visible');
        } else {
            siteHeader && siteHeader.classList.add('is-visible');
        }
    });
};

// Clone header for fixed position version
const cloneHeader = (siteHeader) => {
    return new Promise(resolve => {
        if (!siteHeader.classList.contains('not-fixed')) {
            const siteHeaderClone = siteHeader.cloneNode(true);

            // Set clone attributes and classes
            siteHeaderClone.setAttribute('data-element', 'site-header-clone');
            siteHeaderClone.setAttribute('aria-hidden', true);
            siteHeaderClone.classList.add('is-fixed');

            // Insert into DOM
            siteHeader.parentNode.insertBefore(siteHeaderClone, siteHeader);
        }

        resolve();
    });
};

/**
 * Dropdown menus
 */
const dropdownsInit = () => {
    tippy(document.querySelectorAll('[data-element="nav-dropdown"]'), {
        content: (reference) => {
            const template = document.getElementById(reference.getAttribute('data-dropdown'));

                return template.innerHTML;
        },
        allowHTML: true,
        appendTo: document.body,
        arrow: false,
        interactive: true,
        interactiveBorder: 8,
        interactiveDebounce: 150,
        theme: 'navigation'
    });
};

/**
 * Mobile Menu in static header
 */
const mobileMenuInit = () => {
    const mobileMenu = new Menu({
        name: 'Mobile Menu',
        trigger: '[data-element="mobile-menu-btn"]',
        navigation: '[data-element="mobile-menu"]',
        closeBtn: '[data-element="mobile-menu-close"]',
        canvasClose: '[data-element="canvas-close-mobile-menu"]',
        bodyClass: 'has-mobile-menu'
    });

    mobileMenu.init();
};

/**
 * Public Methods
 */
const headerScroll = {
    init() {
        const siteHeader = document.querySelector('[data-element="site-header"]');

        cloneHeader(siteHeader).then(() => {
            // If browser supports IntersectionObserver
            if (SUPPORTS.intersectionObserver) {
                const observer = new IntersectionObserver(observeHeader);

                // Observe when static position header is out of view
                observer.observe(siteHeader);
            }

            mobileMenuInit();
            dropdownsInit();
        });
    }
};

export default headerScroll;