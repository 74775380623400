const trackEvent = (category, action, label) => {
    gtag('event', label, {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        nonInteraction: true
    });
};

export { trackEvent };
