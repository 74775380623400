export default class savingsNetworkList {
    constructor (savingsNetworkWrapper) {
        this.savingsNetworkWrapper = savingsNetworkWrapper;
        this.savingsNetworkToggle = this.savingsNetworkWrapper.querySelector('[data-element="savings-network-more"]');
        this.savingsNetworkToggleText = this.savingsNetworkToggle.querySelector('span');
        this.savingsNetworkToHide = this.savingsNetworkWrapper.querySelectorAll('[data-element="savings-network-hidden-item"]');

        this.showMoreText = {
            more: "Show More",
            less: "Show Less"
        };

        this.currentShowState = 'more';

        this.initEventListener();
    }

    initEventListener () {
        this.savingsNetworkToggle.addEventListener('click', (event) => {
            event.preventDefault();

            this.savingsNetworkToggle.classList.toggle('is-visible');
            this.currentShowState = this.currentShowState == 'more' ? 'less' : 'more';
            this.savingsNetworkToggleText.innerHTML = this.showMoreText[this.currentShowState];

            this.savingsNetworkToHide.forEach(element => {
                element.classList.toggle('toggle');
            });
        });
    }
};
