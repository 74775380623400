const format = (event, form) => {
    const phoneHint = form.querySelector('[data-element="phone-form-hint"]');

    const number = event.target.value;
    const cleaned = ('' + number).replace(/\D/g, ''); // remove any character that is not a digit
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/); // formats into array ([all numbers, 3 numbers, 3 numbers, 4 numbers])

    if (match) {
        event.target.value = match[1] + '-' + match[2] + '-' + match[3]; // input value set as final format with array indexes set
        phoneHint.style.display = 'none';
    } else {
        phoneHint.style.display = 'block'; // if the user entry was way off, show the form hint to help
    }
};

const formatPhoneNumber = (form) => {
    const phone = form.querySelector('[name="phone"]');

    if (phone) {
        phone.addEventListener('blur', (event) => {
            format(event, form);
        });
    }
};

export { formatPhoneNumber };
