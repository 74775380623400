import axios from '../lib/axios-auth.js';
import config from '../lib/config.js';
import { hideErrors, showErrors } from '../lib/utils.js';
import { resetRecaptchas } from '../lib/recaptcha.js';
import { trackEvent } from '../lib/event-tracking.js';

import { formatPhoneNumber } from './phone-formatting.js';
import setMaxCookie from './set-max-cookie.js';

const formSubmit = (event) => {
    event.preventDefault();

    const form = event.target;
    const formResponse = form.querySelector('[data-element="form-response"]');
    const url = `${config.API_BASE_ROUTE}request-demo`;

    const btn = form.querySelector('[type="submit"]');
    let btnText = btn.innerHTML;

    btn.setAttribute('disabled', true);
    btn.textContent = 'Sending...';

    const formData = new FormData(form);

    axios.post(url, formData).then(({ data }) => {
        // Hide possible errors from previous submissions
        hideErrors(form);

        let successHTML = '';

        if (Object.prototype.hasOwnProperty.call(data, 'success')) {
            successHTML = `<p>${data.success}</p>`;
        } else {
            successHTML = '<p>Thank you for your submission.</p>';
        }

        formResponse.innerHTML = `<div class="success-box u-margin--t-m">${successHTML}</div>`;

        // Display Calendly widget
        const calendlyWidgetContainer = form.querySelector('[data-element="calendly-widget-container"]');
        if (calendlyWidgetContainer) {
            calendlyWidgetContainer.classList.remove('is-hidden');
            buildCalendlyWidget(calendlyWidgetContainer);
            formResponse.parentElement.scrollIntoView({behavior: 'smooth'});
        }

        // Prevent future exit intent modals from showing
        setMaxCookie('Demo');
        trackEvent('Request Demo Form', 'Form Submit', 'Request Demo Successful Submit');

        // LinkedIn tracking
        if (window.lintrk) {
            window.lintrk('track', { conversion_id: 8493348 });
        }
    }).catch(({ response }) => {
        const data = response.data;

        let errorsHTML = '';

        if (Object.prototype.hasOwnProperty.call(data, 'errors') || Object.prototype.hasOwnProperty.call(data, 'error')) {
            if (Object.prototype.hasOwnProperty.call(data, 'errors')) {
                const errors = data.errors;
                errorsHTML += '<ul>';

                Object.keys(errors).forEach(function (item) {
                    errorsHTML += `<li>${errors[item][0]}</li>`;
                });

                errorsHTML += '</ul>';

                // Hide possible errors from previous submissions
                hideErrors(form);
                showErrors(form, errors);
            }

            if (Object.prototype.hasOwnProperty.call(data, 'error')) {
                errorsHTML += `<p>${data.error}</p>`;
            }
        } else if (data.status && data.status == 429) {
            let errorMsg = `<p>The web site is currently busy, please try again soon.</p>`;

            // Hide possible errors from previous submissions
            hideErrors(form);

            errorsHTML = errorMsg;
        } else {
            errorsHTML = `<p>If you're having trouble completing this form, please email your information to <a href="mailto:sales@abenity.com">sales@abenity.com</a>.</p>`;
        }

        formResponse.innerHTML = `<div class="error-box u-margin--t-m">${errorsHTML}</div>`;
    }).finally(() => {
        btn.removeAttribute('disabled');
        btn.innerHTML = btnText;

        resetRecaptchas();
    });
};

const onGoalChange = (event, form) => {
    event.preventDefault();

    const selectInput = event.target;
    const goalText = form.querySelector('[data-element="demo-goal-login"]');
    const submitBtn = form.querySelector('[type="submit"]');

    if (selectInput.value === 'Log In') {
        goalText.setAttribute('aria-hidden', false);
        goalText.style.display = 'block';
        submitBtn.setAttribute('disabled', 'disabled');
    } else {
        goalText.setAttribute('aria-hidden', true);
        goalText.style.display = 'none';
        submitBtn.removeAttribute('disabled');
    }
};

const addEventListeners = (form) => {
    form.addEventListener('submit', formSubmit);

    const goal = form.querySelector('[name="goal"]');
    goal.addEventListener('input', (event) => { onGoalChange(event, form); });

    formatPhoneNumber(form);
};

const buildCalendlyWidget = (container) => {
    const widget = document.createElement('div');
    widget.classList.add('calendly-inline-widget');
    widget.setAttribute('data-url', 'https://calendly.com/sam-abenity/abenity-intro-call-1');
    widget.style.minWidth = '320px';
    widget.style.height = '630px';

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;

    container.appendChild(widget);
    container.appendChild(script);
}

const requestDemoForm = {
    init(form = null) {
        if (form) {
            addEventListeners(form);
        } else {
            const forms = document.querySelectorAll('[data-element="request-demo-form"]');

            if (forms) {
                forms.forEach(form => {
                    addEventListeners(form);
                });
            }
        }
    }
};

export default requestDemoForm;
