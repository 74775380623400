import triggerEvent from 'abenity-core-js/lib/trigger-event.js';
import axios from './axios-auth.js';
import { trackEvent } from './event-tracking.js';

/**
 * @param {String} trigger - the selector(s) for the element that triggers the modal to open
 * @param {String} closeTrigger - the selector(s) for any element that should close the modal
 * @param {String} modal - the selector for the modal element
 * @param {String} bodyClass - an optional name for the class assigned to the body when the menu
 *                             is opened
 * @param {String} url - optionally load the modal from a url
 */
class Modal {
    constructor ({
        trigger,
        modal,
        closeTrigger = '[data-element="modal-close"]',
        modalContent = '[data-element="modal-content"]',
        bodyClass = 'has-modal',
        url = null,
        name = null
    } = {}) {
        // UI State
        this.state = {
            isVisible: false,
            currentTrigger: null
        };

        // Required Arguments
        this.triggers = this.setupElements(trigger);
        this.modal = this.setupElement(modal);

        // Optional Arguments
        this.url = url;
        this.name = name;

        // Used internally
        this.body = document.body;
        this.bodyClass = bodyClass;

        // Selectors scoped to this modal
        this.closeTriggers = this.modal.querySelectorAll(closeTrigger);
        this.modalContent = this.modal.querySelector(modalContent);

        // Validate that all elements are present
        this.validateElements({
            'trigger': this.triggers,
            'closeTrigger': this.closeTriggers,
            'modal': this.modal,
            'modalContent': this.modalContent
        });

        // Pre bind reference to function
        this.keyEvent = this.keyEvent.bind(this);
    }

    init () {
        this.triggers.forEach(el => {
            el.addEventListener('click', (event) => this.onClick(event));
        });

        this.closeTriggers.forEach(el => {
            el.addEventListener('click', (event) => this.onClick(event));
        });

        // Prevent event bubbling from clicks on the modal content
        this.modalContent.addEventListener('click', (event) => event.stopPropagation());
    }

    /**
     *
     * @param {String|NodeList} element - either a string or a NodeList
     * @returns {NodeList}
     */
    setupElements (element) {
        return typeof element === 'string' ? document.querySelectorAll(element) : element;
    }

    /**
     *
     * @param {String|HTMLElement} element - either a string or an HTMLElement
     * @returns {HTMLElement}
     */
    setupElement (element) {
        return typeof element === 'string' ? document.querySelector(element) : element;
    }

    /**
     * Use escape key to close the menu
     * @param {Event} event - key event
     */
    keyEvent (event) {
        const key = event.key;

        if (key === 'Escape') {
            this.closeModal();
        }
    }

    /**
     *
     * @param {Array} elements - an array of DOM Nodes
     * @description - Validate the presence of required elements
     */
    validateElements (elements) {
        Object.keys(elements).forEach(key => {
            if (!elements[key]) {
                console.error(`Menu Error: \u2757 Missing element %c'${elements[key]}'`, 'font-weight: bold;');
            }
        });
    }

    onClick (event) {
        event.preventDefault();

        // Store the current trigger
        this.state.currentTrigger = event.target.closest('a') || event.target.closest('button') || event.target.closest('[data-element="modal-close"]');

        if (this.state.currentTrigger) {
            // If there is a URL on the element or passed as an arg
            this.url = this.state.currentTrigger.getAttribute('data-url') || this.url;
            this.name = this.state.currentTrigger.getAttribute('data-name') || this.name;
        }

        if (this.state.isVisible === false) {
            this.showModal();
        } else {
            this.closeModal();
        }
    }

    closeModal () {
        const handler = () => {
            this.state.isVisible = false;

            // Remove `display: block` class
            // and set aria-hidden attribute
            if (this.state.currentTrigger) {
                this.state.currentTrigger.classList.remove('is-active');
            }

            this.modal.classList.remove('is-active');
            this.modal.setAttribute('aria-hidden', true);

            // remove overflow: hidden
            this.body.classList.remove(this.bodyClass);

            // Empty Modal Content if loading from a url
            if (this.url) {
                this.renderLoadingTemplate();
            }

            // Remove expanded and set focus back to trigger
            // that opened the menu
            if (this.state.currentTrigger) {
                this.state.currentTrigger.setAttribute('aria-expanded', false);
                this.state.currentTrigger.focus();

                triggerEvent(this.state.currentTrigger, 'modal:closed');
                this.state.currentTrigger = null;
            }

            // Clean up transitionend event for closing
            this.modal.removeEventListener('transitionend', handler, false);

            // Clean up key event for esc key
            document.removeEventListener('keydown', this.keyEvent);
        };

        // Removing transition class for animation
        // will trigger transitionend event listener
        this.modal.addEventListener('transitionend', handler, false);
        this.modal.classList.remove('is-visible');
    }

    showModal () {
        this.state.isVisible = true;

        // Add class to prevent overflow scrolling
        this.body.classList.add(this.bodyClass);

        // Add the `display: block` class
        this.modal.classList.add('is-active');
        this.modal.setAttribute('aria-hidden', false);

        // Set expanded to true and focus on the modal for tabindex
        if (this.state.currentTrigger) {
            this.state.currentTrigger.setAttribute('aria-expanded', true);
        }

        this.modal.focus();

        const handler = () => {
            // Set up key event for esc key
            document.addEventListener('keydown', this.keyEvent);

            // Clean up transitionend event
            this.modal.removeEventListener('transitionend', handler, false);
        };

        // Add transitionend event
        this.modal.addEventListener('transitionend', handler, false);

        // Slight delay after display: block, so the animation can be seen
        setTimeout(() => {
            // Add animation classes
            this.modal.classList.add('is-visible');

            if (this.state.currentTrigger) {
                triggerEvent(this.state.currentTrigger, 'modal:shown');
            }

            // If there is a url passed get the content for the modal
            if (this.url) {
                this.getModalContent();
            }

            if (this.name) {
                trackEvent('Overlays', 'Overlay Shown', `${this.name} Overlay`);
            }
        }, 25);
    }

    getModalContent () {
        axios.get(this.url)
            .then(response => {
                const doc = document.createElement('div');
                doc.innerHTML = response.data;

                this.modalContent.innerHTML = doc.querySelector('[data-element="modal-url-target"]').innerHTML;

                if (this.state.currentTrigger) {
                    if (this.state.currentTrigger.hasAttribute('data-event-params')) {
                        const params = {
                            bubbles: false,
                            cancelable: false,
                            detail: {
                                'modal': this.state.currentTrigger.getAttribute('data-event-params')
                            }
                        };

                        triggerEvent(this.state.currentTrigger, 'modal:contentloaded', params);
                    } else {
                        triggerEvent(this.state.currentTrigger, 'modal:contentloaded');
                    }
                }
            });
    }

    renderLoadingTemplate () {
        this.modalContent.innerHTML = `
            <div class="loading-animation">
                <p class="hd-26-black u-c--blue u-ta--center u-margin--b-0">Loading</p>
                <div class="loading-animation__circles">
                    <div class="circle"></div>
                    <div class="circle"></div>
                    <div class="circle"></div>
                </div>
            </div>
        `;
    }
}

export { Modal };
