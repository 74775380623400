import { Modal } from '../lib/modal.js';
import { hideErrors, showErrors } from '../lib/utils.js';

import axios from '../lib/axios-auth.js';
import config from '../lib/config.js';
import passwordToggle from './password-toggle.js';
import triggerEvent from 'abenity-core-js/lib/trigger-event.js';
import { trackEvent } from '../lib/event-tracking.js';

// Set the initial global button state
let currentBtnState = 'passwordless';

// Define the passwordless UI text
const helpText = {
    passwordless: "Click to sign in without a password and receive a one-time log in link by email.",
    usePassword: "Click to log in using a username and password."
};
const passwordlessBtnText = {
    passwordless: "Passwordless",
    usePassword: "Use Password"
};

class LoginToggle {
    constructor ({
        element
    }) {
        this.trigger = element;
        this.radios = document.querySelectorAll('[data-element="login-radio"]');
        this.dropdown = document.querySelector('[data-element="login-options-list"]');

        this.state = {
            listVisible: false
        };
    }

    init () {
        this.trigger.addEventListener('click', (event) => {
            this.showList(event);
        });

        this.radios.forEach(radio => {
            radio.addEventListener('change', (event) => {
                this.radioSelect(event);
            });

            radio.addEventListener('click', (event) => {
                this.radioSelect(event);
            });
        });

        this.detectOutsideClick = this.detectOutsideClick.bind(this);
    }

    detectOutsideClick (event) {
        // If it the dropdown list is visible
        // and it is an element outside the dropdown
        // and it's outside the trigger and not the trigger
        if (this.state && this.state.listVisible && !this.dropdown.contains(event.target) && (event.target !== this.trigger && !this.trigger.contains(event.target))) {
            this.toggleList();
        }
    }

    toggleList () {
        this.dropdown.classList.toggle('is-visible');
        this.state.listVisible = !this.state.listVisible;

        if (this.state && this.state.listVisible) {
            document.addEventListener('click', this.detectOutsideClick);
        } else {
            document.removeEventListener('click', this.detectOutsideClick);
        }
    }

    showList (event) {
        event.preventDefault();

        if (this.state && !this.state.listVisible) {
            this.toggleList();
        }
    }

    radioSelect (event) {
        const radioLabel = event.target.labels[0];
        this.trigger.innerHTML = radioLabel.innerHTML;

        if (this.state && this.state.listVisible) {
            this.toggleList();
        }

        // Reset the password toggle state on selection
        this.resetPasswordlessToggle(event.target.value);
    }

    resetPasswordlessToggle (appName) {
        const passwordlessBtn = document.querySelector('[data-element="passwordless-btn"]');
        const passwordlessHelp = document.querySelector('[data-element="passwordless-help"]');
        const passwordlessInput = document.querySelector('.passwordless-input');
        const passwordInput = document.querySelector('[data-element="password-input"]');
        const passwordHideToggle = document.querySelector('[data-element="password-toggle"]');

        passwordlessHelp.innerHTML = helpText.passwordless;
        passwordlessBtn.innerHTML = passwordlessBtnText.passwordless;
        passwordInput.classList.remove('toggle');
        passwordHideToggle.classList.remove('hide');
        passwordlessBtn.classList.remove('toggle');
        passwordlessInput.disabled = true;
        passwordInput.disabled = false;
        currentBtnState = 'passwordless';

        if (appName != 'perks') {
            // Hide the button
            passwordlessBtn.classList.add('hide');
            passwordlessHelp.classList.add('hide');
        } else {
            passwordlessBtn.classList.remove('hide');
            passwordlessHelp.classList.remove('hide');
        }
    }
}

function onSubmit (event) {
    event.preventDefault();

    const form = event.target;
    let url = `${config.API_BASE_ROUTE}login`;
    let passwordlessLogin = false;

    const passwordlessInput = document.querySelector('.passwordless-input');
    const responseDiv = form.querySelector('.js-form-response');
    const btn = form.querySelector('[type="submit"]');
    const btnText = btn.innerHTML;

    btn.setAttribute('disabled', true);
    btn.textContent = 'Sending...';

    // Get destination value
    let destination;
    form.querySelectorAll('[name="destination"]').forEach(radio => {
        if (radio.checked) {
            destination = radio.value;
        }
    });

    if (!passwordlessInput.disabled && destination == 'perks') {
        passwordlessLogin = true;
        url = '/login-without-password/request';
    }

    let formData = {
        username: form.querySelector('[name="username"]').value
    };

    if (!passwordlessLogin) {
        formData.password = form.querySelector('[name="password"]').value;
        formData.destination = destination;
    }

    axios.post(url, formData)
        .then(({ data }) => {
            // Hide possible errors from previous submissions
            hideErrors(form);

            if (!passwordlessLogin) {
                trackEvent('Login Form', 'Form Submit', 'Login Successful Submit');
            } else {
                trackEvent('Login Form', 'Passwordless Form Submit', 'Passwordless Login Successful Submit');
            }

            intializeRedirect(form, data.redirectUrl, passwordlessLogin);
        }).catch(({ response }) => {
            const data = response.data;

            if (data.hasOwnProperty('errors')) {
                const errors = data.errors;
                let errorMsg = '<div class="error-box u-margin--t-m"><ul>';

                Object.keys(errors).forEach((key) => {
                    errorMsg += `<li>${errors[key][0]}</li>`;
                });

                errorMsg += '</ul></div>';
                responseDiv.innerHTML = errorMsg;

                // Hide possible errors from previous submissions
                hideErrors(form);

                // Add error class to form items
                showErrors(form, errors);
            } else if (data.hasOwnProperty('error')) {
                let errorMsg = `<div class="error-box u-margin--t-m"><ul><li>${data.error}</li></ul></div>`;

                responseDiv.innerHTML = errorMsg;

                // Hide possible errors from previous submissions
                hideErrors(form);
            } else if (data.status && data.status == 429 || response.status && response.status == 429) {
                let errorMsg = `<div class="error-box u-margin--t-m"><ul><li>The web site is currently busy, please try again soon.</li></ul></div>`;

                responseDiv.innerHTML = errorMsg;

                // Hide possible errors from previous submissions
                hideErrors(form);
            }

            btn.removeAttribute('disabled');
            btn.innerHTML = btnText;
        });
}

const intializeRedirect = (form, redirectUrl, passwordlessLogin) => {
    let timer = 5;
    let successMsg;

    if (!passwordlessLogin) {
        successMsg = `<div class="success-box u-margin--t-m">Login Successful! Redirecting in <span id="js-redirect-timer">5</span></div>`;
    } else {
        successMsg = `<div class="success-box u-margin--t-m">Login Link Requested! Redirecting in <span id="js-redirect-timer">5</span></div>`;
    }

    form.querySelector('.js-form-response').innerHTML = successMsg;

    const $redirectTimer = document.getElementById('js-redirect-timer');
    $redirectTimer.innerHTML = timer;

    let interval = setInterval(() => {
        // Start redirect with 2 seconds left for seamless UX
        if (timer == 2) {
            window.location.replace(redirectUrl);
        } else if (timer == 0) {
            clearInterval(interval);
            return;
        }

        timer -= 1;
        $redirectTimer.innerHTML = timer;
    }, 1000);
};

const loginInit = () => {
    const element = document.querySelector('[data-element="login-dropdown"]');

    const toggle = new LoginToggle({
        element
    });

    toggle.init();

    const form = document.querySelector('[name="login"]');

    // Add form response div
    const response = document.createElement('div');
    response.classList.add('js-form-response');
    form.appendChild(response);

    form.addEventListener('submit', onSubmit);

    // Initialize passwordless button toggle
    passwordlessToggleInit(form);
};

const modalInit = () => {
    const modalTriggers = document.querySelectorAll('[data-element="modal-login-trigger"]');
    const loginModal = document.querySelector('[data-element="modal-login"]');

    if (modalTriggers && loginModal) {
        const modal = new Modal({
            trigger: modalTriggers,
            modal: '[data-element="modal-login"]',
            name: 'Login'
        });

        modal.init();

        // Init script after HTML is inserted
        modalTriggers.forEach(trigger => {
            trigger.addEventListener('modal:contentloaded', () => {
                loginInit();
                passwordToggle.init();

                const destinationRadio = document.getElementById(trigger.getAttribute('data-destination'));

                if (trigger.hasAttribute('data-destination') && destinationRadio) {
                    destinationRadio.checked = true;
                    triggerEvent(destinationRadio, 'change');
                }

                // Send Microsoft Ads event to filter out logins
                window.uetq = window.uetq || [];
                window.uetq.push('event', 'login_modal_shown', {'event_category': 'login_modal', 'event_label': 'open_login_overlay', 'event_value': '0'});
            });
        });
    }
};

const passwordlessToggleInit = (form) => {
    const passwordlessBtn = form.querySelector('[data-element="passwordless-btn"]');
    const passwordlessHelp = form.querySelector('[data-element="passwordless-help"]');
    const passwordlessInput = form.querySelector('.passwordless-input');
    const passwordInput = form.querySelector('[data-element="password-input"]');
    const passwordHideToggle = form.querySelector('[data-element="password-toggle"]');

    passwordlessBtn.addEventListener('click', () => {
        // Toggle the help text and state
        if (currentBtnState == 'passwordless') {
            passwordlessHelp.innerHTML = helpText.usePassword;
            passwordlessBtn.innerHTML = passwordlessBtnText.usePassword;
            currentBtnState = 'usePassword';
            passwordlessInput.disabled = false;
            passwordInput.disabled = true;
        } else {
            passwordlessHelp.innerHTML = helpText.passwordless;
            passwordlessBtn.innerHTML = passwordlessBtnText.passwordless;
            currentBtnState = 'passwordless';
            passwordInput.classList.toggle('hide');
            passwordlessInput.disabled = true;
            passwordInput.disabled = false;
        }

        passwordInput.classList.toggle('toggle');
        passwordHideToggle.classList.toggle('hide');
        passwordlessBtn.classList.toggle('toggle');
    });
}

const loginDropdown = {
    init () {
        const element = document.querySelector('[data-element="login-dropdown"]');

        if (element) {
            // if on the login page, init on page load
            loginInit();
            passwordToggle.init();
        } else {
            // if launching the modal, init when content is loaded
            modalInit();
        }
    }
};

export default loginDropdown;
